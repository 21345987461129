
/*==================> 7. event-details-page <=================*/

.wpo-event-details-text {
    margin-top: 50px;
}

.wpo-event-details-text h2 {
    margin-bottom: 30px;
    font-weight: 700;

}

.wpo-event-details-wrap {
    margin-top: 40px;
}

.wpo-event-details-tab .nav {
    justify-content: space-between;
    display: flex;
}
@media(max-width: 590px) {
    .wpo-event-details-tab .nav {
        display: block;
    }
}
.wpo-event-details-tab .nav-tabs>li {
    margin-bottom: -1px;
    width: 100%;
    flex-basis: 33.33%;
}

.wpo-event-details-wrap {
    border: 1px solid #ebebeb;
}

.wpo-event-details-tab .nav li a {
    display: block;
    text-align: center;
    color: #2d4277;
    transition: all .5s;
    font-weight: 400;
    margin-right: 1px;
    border: none;
    border-radius: 0px;
    background: #f6f6f6;
    border-bottom: none;
    border-top: 4px solid transparent;
    line-height: 20px;
    height: 50px;
}

.wpo-event-details-tab .nav li:last-child a {
    margin-right: -1px;
}

.wpo-event-details-tab .nav li a.active ,
.wpo-event-details-tab .nav li:hover a {
    background: #fff;
    outline: none;
    border: none;
    color: #2d4277;
    border-top: 4px solid $theme-primary-color;
}

.wpo-event-details-content {
    padding: 30px;
}

#Schedule ul {
    list-style: none;
    margin-top: 30px;
}

#Schedule ul li {
    font-size: 15px;
    font-size: 0.9375rem;
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
}

#Schedule ul li:before {
    content: "";
    background: $theme-primary-color;
    width: 6px;
    height: 6px;
    position: absolute;
    left: 0;
    top: 6px;
}

#Map .contact-map iframe {
    width: 100%;
    height: 500px;
}

.event-contact .wpo-donations-details {
    border: none;
    padding: 0;
}

.event-contact .wpo-donations-details .theme-btn {
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: none;
    margin-top: 20px;
}

@media(max-width: 590px) {
    .wpo-event-details-tab .nav li a {
        font-size: 12px;
    }
}

@media(max-width: 400px) {
    .wpo-event-details-tab .nav {
        flex-wrap: wrap;
    }

    .wpo-event-details-tab .nav li a {
        margin-bottom: 15px;
        padding: 12px;
    }
}

.wpo-event-img,
.wpo-case-details-img{
    img{
        width: 100%;
    }
}
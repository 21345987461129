/*==================> 6. Home-style-3 <=================*/


/*6.1 pillars-section-s3 */
.pillars-section-s3 {
    padding-top: 0;
    background: $white;

    &:before {
        display: none;
    }

    .pillars-card {
        text-align: center;
        width: 20%;


        .image {
            max-height: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 100%;
            box-shadow: 2px 10px 50px 2px rgba(0, 0, 0, 0.10);

            @media (max-width:1199px) {
                border-radius: 100%;
            }

            @media (max-width:767px) {
                border-radius: 100%;
                max-width: fit-content;
            }



            img {
                border-radius: 156px;
                border: 20px solid $white;
                width: 320px;
                height: 100%;
                object-fit: cover;

            }
        }
    }
}

/*6.2 prayertime-section-s3 */
.prayertime-section-s3 {
    top: 0;
    border-radius: 0px;
    margin-bottom: -30px;
    background: $theme-primary-color;

    .prayertime-wrap {
        background: transparent;
        border-radius: 0px;
    }

    @media (max-width:991px) {
        margin-top: 0;
    }
}

/*6.3 about-section-s3 */
.about-section-s3 {
    padding-top: 0;

    @media (max-width: 1399px) {
        .content {
            padding: 0px 0 0px;
        }
    }

    @media (max-width: 991px) {
        .content {
            margin-top: 50px;
        }
    }

    @media (max-width: 991px) {
        .image {
            max-width: 500px;
            margin: 0 auto;
        }
    }
}
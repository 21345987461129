/*--------------------------------------------------------------
4. footer-section
--------------------------------------------------------------*/
.site-footer {
    background: $black;
    position: relative;
    font-size: 15px;
    overflow: hidden;

    ul {
        list-style: none;
    }

    p {
        color: $white;
    }

    li {
        color: $white;
    }

    .container {
        position: relative;
    }

    .upper-footer {
        padding: 195px 0 80px;

        &.s2 {
            padding-top: 100px;

            @media (max-width: 991px) {
                padding-top: 80px;
            }
        }
    }

    @media (max-width: 991px) {

        .upper-footer {
            padding: 50px 0 0;
        }
    }

    @media (max-width: 767px) {

        .upper-footer {
            padding: 60px 0 0;
        }
    }

    @media (max-width: 991px) {

        .upper-footer .col {
            min-height: 235px;
            margin-bottom: 70px;
        }
    }

    @media (max-width: 767px) {

        .upper-footer .col {
            min-height: auto;
            margin-bottom: 60px;
        }
    }

    .widget-title {
        margin-bottom: 30px;
    }

    @media (max-width: 767px) {

        .widget-title {
            margin-bottom: 20px;
        }
    }


    .widget-title {
        h3 {
            color: $white;
            margin: 0;
            position: relative;
            font-family: $heading-font;
            font-size: 25px;
            font-weight: 700;
            text-transform: capitalize;
        }
    }

    .about-widget {
        .logo {
            max-width: 180px;
        }
    }

    .about-widget {
        p {
            margin-bottom: 18px;
            line-height: 1.9em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }


    .link-widget {
        overflow: hidden;


        @media screen and (min-width: 1200px) {
            padding-left: 60px;
        }

        @media (max-width: 1199px) {
            padding-left: 20px;
        }

        @media (max-width: 991px) {
            padding-left: 0;
        }

        @media (max-width: 767px) {
            max-width: 350px;
        }

        &.s2 {
            padding-left: 0;
        }

        ul {
            li {
                position: relative;

                a {
                    color: $white;
                    padding-left: 15px;
                    position: relative;
                    transition: all .3s ease-in-out;
                    font-size: 18px;
                    font-weight: 400;

                    &::before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        width: 6px;
                        height: 6px;
                        border-radius: 10px;
                        content: '';
                        background: $white;
                        transform: translateY(-50%);
                        transition: all .3s ease-in-out;
                    }

                    &:hover {
                        color: $theme-primary-color;

                        &::before {
                            background: $theme-primary-color;
                        }
                    }
                }
            }

            li+li {
                padding-top: 10px;
            }
        }
    }



    .social-widget {
        ul {
            li {
                display: inline-block;
                margin-right: 10px;

                a {
                    display: block;
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 100px;
                    background: rgb(255, 255, 255, 0.15);
                    text-align: center;

                    &:hover {
                        color: $theme-primary-color;
                    }

                }
            }
        }
    }

    .newsletter-widget {
        .form-control {
            width: 100%;
            border-radius: 6px;
            border: 1px solid #989898;
            color: #FFF;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            background: transparent;
            padding: 12px 20px;

            &:focus {
                box-shadow: transparent;
                box-shadow: 0 0 0 0.25rem transparent;
            }
        }

        .theme-btn {
            width: 100%;
            border-radius: 6px;
            margin-top: 30px;
        }
    }


    .lower-footer {
        text-align: center;
        position: relative;

        .row {
            padding: 20px 0;
            position: relative;
            border-top: 1px solid #30465C;
        }

        .copyright {
            margin: 0;
            color: #9F9F9F;
            font-size: 18px;
            font-weight: 400;
            text-align: left;

            @media (max-width:1199px) {
                font-size: 16px;
            }

            @media (max-width: 991px) {
                text-align: center;
            }

            a {
                color: $white;
                text-decoration: none;
            }

            @media (max-width: 991px) {
                float: none;
                display: block;
            }
        }

        ul {
            text-align: end;

            @media (max-width: 991px) {
                text-align: center;
                margin-top: 10px;
            }

            li {
                padding-right: 20px;
                display: inline-block;

                &:last-child {
                    padding-right: 0;
                }

                a {
                    color: #AAA;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 35px;
                    transition: all .3s ease-in-out;

                    @media (max-width:1199px) {
                        font-size: 16px;
                    }

                    &:hover {
                        color: $theme-primary-color;
                    }
                }

            }
        }
    }

    .instagram {
        ul {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -3px;

            li {
                -ms-flex: 0 0 33.33%;
                flex: 0 0 33.33%;
                max-width: 33.33%;
                margin-bottom: 5px;
                padding: 0px 3px;

                img {
                    width: 100%;
                }
            }
        }
    }
}
.section-title {
	margin-bottom: 55px;
	text-align: center;

	@include media-query(767px) {
		margin-bottom: 40px;
	}

	h2 {
		color: $theme-primary-color;
		font-family: $base-font;
		font-size: 20px;
		font-weight: 600;
		line-height: 35px;
		margin-bottom: 3px;
	}

	h3 {
		margin: 0;
		text-transform: capitalize;
		color: $heading-color;
		font-size: 40px;
		font-weight: 700;
		line-height: 50px;

		@include media-query(1399px) {
			font-size: 35px;
			line-height: 40px;
		}

		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		color: #525252;
		font-size: 18px;
		font-weight: 400;
		line-height: 30px;
		margin-bottom: 0;
		@include media-query(575px) {
			font-size: 16px;
		}

	}

}
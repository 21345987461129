/*--------------------------------------------------------------
3. content
--------------------------------------------------------------*/

/* 3.1 hero-section */


/* static-hero */
.static-hero {
	margin: 0 60px;
	height: 900px;
	border-radius: 100px;
	background: url(../../images/slider/bg.png);
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding: 30px;
	padding-left: 130px;
	display: flex;
	align-items: center;

	@media (max-width:1780px) {
		padding-left: 80px;
	}

	@media (max-width:1730px) {
		padding-left: 20px;
	}

	@media (max-width:1690px) {
		height: 800px;
	}

	@media (max-width:1399px) {
		height: 750px;
	}

	@media (max-width:1199px) {
		margin: 0 20px;
		height: 700px;
	}

	@media (max-width:991px) {
		display: block;
		height: 100%;
		padding: 30px;
	}

	@media (max-width:575px) {
		padding: 20px 0px;
		border-radius: 20px;
	}

	&::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #F6F6F6;
		z-index: -11;
		opacity: .7;
	}


	.content {
		max-width: 690px;
		text-align: center;

		@media (max-width:1690px) {
			max-width: 600px;
		}

		@media (max-width:1399px) {
			max-width: 500px;
		}

		@media (max-width:1199px) {
			max-width: 400px;
		}

		@media (max-width:991px) {
			max-width: 600px;
			margin: 0 auto;
			margin-bottom: 40px;
		}

		.icon {
			margin-bottom: 20px;
		}

		.title {
			color: $black;
			font-size: 50px;
			font-weight: 700;
			line-height: 80px;
			text-transform: capitalize;
			margin-bottom: 13px;

			@media (max-width:1399px) {
				font-size: 40px;
				line-height: 60px;
			}

			@media (max-width:1199px) {
				font-size: 35px;
				line-height: 60px;
			}

			@media (max-width:575px) {
				font-size: 19px;
				line-height: 33px;
			}
		}

		.subtitle {
			color: #000;
			font-size: 18px;
			font-weight: 600;
			line-height: 30px;
			text-transform: capitalize;

			@media (max-width:575px) {
				font-size: 15px;
				line-height: 25px;
			}
		}

		.hero-btn {
			margin-top: 45px;
		}

	}

	.right-img {
		max-width: 780px;
		max-height: 840px;
		position: absolute;
		right: 30px;
		top: 30px;

		@media (max-width:1690px) {
			max-width: 600px;
			top: 50%;
			transform: translateY(-50%);
		}

		@media (max-width:1399px) {
			max-width: 500px;
		}

		@media (max-width:1199px) {
			max-width: 400px;
		}

		@media (max-width:991px) {
			position: unset;
			top: auto;
			transform: unset;
			margin: 0 auto;
		}

		img {
			width: 100%;
			border-radius: 60px;

			@media (max-width:991px) {
				border-radius: 10px;
			}
		}
	}
}


.hero-slider {
	width: 100%;
	height: 840px;
	display: flex;
	position: relative;
	z-index: 0;

	@include media-query(991px) {
		height: 600px;
	}

	@include media-query(767px) {
		height: 600px;
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-container,
	.hero-container {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.slide-inner,
	.hero-inner {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		background-size: cover;
		background-position: center;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: left;

		&:before {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: #040128;
			content: "";
			opacity: .4;
		}

		.slide-content {
			padding-left: 240px;
			margin-top: -80px;
			position: relative;

			@media(max-width:1700px) {
				padding-left: 140px;
			}

			@media(max-width:1199px) {
				padding-left: 85px;
				margin-top: -100px;
			}

			@media(max-width:991px) {
				padding-left: 30px;
				padding-top: 35px;
			}

			@media(max-width:767px) {
				padding-left: 10px;
			}



			.slide-title {
				max-width: 819px;

				h2 {
					color: $white;
					font-family: $heading-font;
					font-size: 70px;
					font-weight: 700;
					line-height: 100px;
					text-transform: capitalize;

					@media(max-width:1199px) {
						font-size: 55px;
						line-height: 80px;
					}

					@media(max-width:991px) {
						font-size: 45px;
						line-height: 70px;
					}

					@media(max-width:575px) {
						font-size: 30px;
						line-height: 55px;
					}
				}
			}

			.slide-text {
				max-width: 615px;
				margin-bottom: 50px;

				@media(max-width:575px) {
					margin-bottom: 40px;
				}

				p {
					color: $white;
					font-size: 18px;
					font-weight: 600;
					line-height: 30px;
					text-transform: capitalize;

					@media(max-width:575px) {
						font-size: 15px;
						line-height: 25px;
					}
				}
			}
		}
	}




	// slider controls
	.swiper-button-prev,
	.swiper-button-next {
		background: transparentize($white, 0.8);
		width: 55px;
		height: 55px;
		line-height: 55px;
		border: 1px solid $white;
		border-radius: 50%;
		opacity: 0.7;
		text-align: center;
		@include transition-time(0.3s);

		&:hover {
			opacity: 0.9;
		}

		@include media-query(767px) {
			display: none;
		}
	}

	.swiper-button-prev {
		left: 25px;

		&:after {
			font-family: "themify";
			content: "\e629";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-next {
		right: 25px;

		&:after {
			font-family: "themify";
			content: "\e628";
			font-size: 20px;
			color: #fff;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		@include media-query(991px) {
			display: none;
		}
	}

	.swiper-pagination-bullet {
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
		font-size: 12px;
		color: #000;
		opacity: 1;
		background: rgba(255, 255, 255, 0.2);

		@include widther(992px) {
			display: none;
		}
	}

	.swiper-pagination-bullet-active {
		color: #fff;
		background: $white;
		width: 12px;
		height: 12px;
		text-align: center;
		line-height: 12px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets,
	.swiper-pagination-custom,
	.swiper-pagination-fraction {
		bottom: 50px;
	}

	.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
		margin: 0 8px;
	}


}

/* static-hero-s2 */
.static-hero-s2 {
	height: 860px;
	background: #FFFBF4;
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	padding-left: 210px;

	@media (max-width:1760px) {
		padding-left: 150px;
	}

	@media (max-width:1699px) {
		padding-left: 100px;
	}
	@media (max-width:1399px) {
		height: 750px;
	}
	@media (max-width:1199px) {
		padding-left: 20px;
		padding-top: 50px;
	}
	@media (max-width:991px) {
		height: 1000px;
		display: block;
		padding-left: 0;
	}
	@media (max-width:575px) {
		height: 900px;
	}
	@media (max-width:575px) {
		height: 900px;
	}

	.content {
		text-align: left;
		max-width: 777px;

		@media (max-width:1690px) {
			max-width: 600px;
		}

		@media (max-width:1399px) {
			max-width: 500px;
		}

		@media (max-width:1199px) {
			max-width: 400px;
		}

		@media (max-width:991px) {
			max-width: 600px;
			margin: 0 auto;
			margin-bottom: 40px;
			text-align: center;
			margin-top: 80px;
		}

		.icon {
			margin-bottom: 20px;
		}

		.title {
			color: $black;
			font-size: 50px;
			font-weight: 700;
			line-height: 80px;
			text-transform: capitalize;
			margin-bottom: 13px;

			@media (max-width:1399px) {
				font-size: 40px;
				line-height: 60px;
			}

			@media (max-width:1199px) {
				font-size: 35px;
				line-height: 60px;
			}

			@media (max-width:575px) {
				font-size: 19px;
				line-height: 33px;
			}
		}

		.subtitle {
			color: #000;
			font-size: 18px;
			font-weight: 600;
			line-height: 30px;
			text-transform: capitalize;
			max-width: 600px;
			display: block;

			@media (max-width:575px) {
				font-size: 15px;
				line-height: 25px;
			}
		}

		.hero-btn {
			margin-top: 45px;
		}

	}

	.image {
		position: absolute;
		right: -10%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		max-width: 715px;
		max-height: 652px;

		@media (max-width:1699px) {
			right: -15%;
		}

		@media (max-width:1499px) {
			right: -25%;
		}

		@media (max-width:1399px) {
			right: -15%;
			max-width: 515px;
			max-height: 552px;
		}
		@media (max-width:1199px) {
			right: -25%;
		}
		@media (max-width:991px) {
			position: unset;
			transform: unset;
			max-width: 400px;
			max-height: 400px;
			margin: 0 auto;
		}

	}
}
/*---------------------------
	Fonts
----------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');




// fonts
$base-font-size: 15;
$base-font: 'Mulish', sans-serif;
$heading-font: 'Cinzel Decorative', serif;


// color
$dark-gray: #262626;//
$body-color: #687693;
$white: #fff;
$light: #625F71;//
$black: #000;
$small-black: #525252;//

$theme-primary-color: #1D7BC9;//
$body-bg-color: #fff;
$section-bg-color: #9F9F9F;//
$text-color: #6e6e6e;
$text-light-color: #E8E8E8;//
$heading-color: $dark-gray;
$border-color: #A6A6A6;//
$border-color-s2: #30465C;//
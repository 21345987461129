.theme-btn {
	background: $theme-primary-color;
	color: $white;
	display: inline-block;
	padding: 12px 45px;
	border: 0;
	border-radius: 20px;
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 18px;
	font-weight: 700;
	line-height: 35px;

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 40px;
		background: darken($theme-primary-color, 8%);
		transition: opacity 0.5s ease-out;
		opacity: 0;
		z-index: -1;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken($theme-primary-color, 5%);
		color: $white;
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		line-height: 28px;
	}

	@include media-query(767px) {
		padding: 12px 25px;
	}
}

.view-cart-btn {
	@extend .theme-btn;
	display: block;
	margin-top: 15px;
	border-radius: 5px;
	padding: 10px 45px;



	&:after {
		display: none;
	}

	&:hover {
		background-color: darken($theme-primary-color, 8%);
	}

	&.s1 {
		background: #ddd;

		&:hover {
			background-color: darken(#ddd, 8%);
		}
	}

}
/*==================> 5. Home-style-2 <=================/*

/*5.1 prayertime-section-s2 */
.prayertime-section-s2,
.prayertime-section-s3 {
    position: relative;
    z-index: 1;
    top: -80px;

    @media (max-width:991px) {
        top: 0;
        margin-bottom: 0;
        margin-top: 80px;
    }

    .prayertime-wrap {
        padding: 40px 100px 10px;
        border-radius: 40px;

        @media (max-width:1199px) {
            padding: 40px 40px 10px;
        }
    }
}

/*5.2 about-section-s2 */
.about-section-s2 {
    margin-top: 20px;

    @media (max-width:1199px) {
        margin-top: 0;
    }

    .image {
        border-radius: 325px 325px 0px 0px;
        box-shadow: 2px 10px 50px 2px rgba(0, 0, 0, 0.10);

        @media (max-width:991px) {
            max-width: 500px;
            margin: 0 auto;
        }

        img {
            width: 100%;
            border-radius: 325px 325px 0px 0px;
            padding: 20px;
        }
    }
}

/*5.3 pillars-section-s2 */
.pillars-section-s2 {

    .pillars-card {
        text-align: center;
        width: 20%;

        @media (max-width:1399px) {
            margin-left: 10px;
            margin-right: 10px;
        }


        .image {
            max-height: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 156px;
            box-shadow: 2px 10px 50px 2px rgba(0, 0, 0, 0.10);

            @media (max-width:1199px) {
                border-radius: 156px;
            }

            @media (max-width:767px) {
                border-radius: 156px;
                max-width: fit-content;
            }



            img {
                border-radius: 156px;
                border: 20px solid $white;
                width: 320px;
                height: 450px;
                object-fit: cover;

                @media (max-width:1399px) {
                    height: 360px;
                }

                @media (max-width:767px) {
                    height: 400px;
                }



            }
        }
    }
}

/*5.4 service-section-s2 */
.service-section-s2 {
    .service-card {
        box-shadow: 0px 2px 8px 0px transparent;
        position: relative;
        overflow: hidden;
        background: transparent;
        transition: all .3s ease-in-out;

        .top-number {
            position: absolute;
            right: 30px;
            top: 25px;
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;

            span {
                font-size: 60px;
                font-weight: 700;
                line-height: 50px;
                text-transform: capitalize;
                font-family: $heading-font;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: $white;
                color: transparent;
            }

        }

        .icon {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;
        }

        .text {
            opacity: 0;
            visibility: hidden;
            transition: all .3s ease-in-out;

            h2 {
                color: $white;
            }

            p {
                color: $white;
            }

            a {
                color: $white;

                &:hover {
                    color: $heading-color;
                }
            }
        }

        .image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            transition: all .3s ease-in-out;
            opacity: 1;
            visibility: visible;

            &::before {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                content: '';
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .img-title {
                position: absolute;
                bottom: 30px;
                text-align: center;
                transform: translateX(-50%);
                left: 50%;
                width: 100%;
                color: $white;
                font-size: 25px;
                font-weight: 700;
                line-height: 50px;
                text-transform: capitalize;

                @media (max-width:1399px) {
                    font-size: 20px;
                }

                @media (max-width:575px) {
                    font-size: 18px;
                }
            }
        }

        &:hover {
            background: $theme-primary-color;

            .top-number {
                opacity: 1;
                visibility: visible;
            }

            .icon {
                opacity: 1;
                visibility: visible;
            }

            .text {
                opacity: 1;
                visibility: visible;
            }

            .image {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

/*5.5 funfact-section-s2 */
.funfact-section-s2 {
    background: url(../../images/funfact-bg-s2.jpg);
}

/*5.6 event-section-s2 */
.event-section-s2 {


    .slick-list {
        margin-left: -20px;
        margin-right: -20px;
    }

    .slick-slide {
        padding-left: 20px;
        padding-right: 20px;
    }


    .slick-prev,
    .slick-next {
        border-radius: 5px;
        border: 1px solid #A6A6A6;
        background: #FFF;
        width: 50px;
        height: 50px;
        transition: all .3s ease-in-out;
        z-index: 1;

        &:hover {
            border: 1px solid $theme-primary-color;
            color: $theme-primary-color;

            &::before {
                color: $theme-primary-color;
            }
        }
    }

    .slick-prev {
        left: -80px;

        @media (max-width:1450px) {
            left: -60px;
        }

        @media (max-width:1399px) {
            left: -30px;
        }

    }

    .slick-next {
        right: -80px;

        @media (max-width:1450px) {
            right: -60px;
        }

        @media (max-width:1399px) {
            right: -30px;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #A6A6A6;
        content: "\e629";
        font-family: themify;
        font-size: 18px;
        transition: all .3s ease-in-out;
    }

    .slick-next:before {
        content: "\e628";
    }

    .slick-dots {
        bottom: -50px;
    }

    .slick-dots li {
        margin: 0;

    }

    button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: rgba(43, 17, 15, 0.10);

        &::before {
            font-size: 0;
        }
    }

    .slick-dots li button {
        width: 15px;
        height: 15px;
        background: rgba(43, 17, 15, 0.10);
        margin: 0 3px;
    }

    .slick-dots li button:before {
        font-size: 0px;
    }

    li.slick-active button {
        background-color: $theme-primary-color;
    }
}

// event-single-card 
.event-single-card {
    .image {
        border-radius: 208px 208px 0px 0px;
        background: $white;
        box-shadow: 2px 10px 50px 2px rgba(0, 0, 0, 0.10);
        padding: 20px;
        margin-top: 20px;

        @media (max-width:757px) {
            padding: 10px;
        }

        img {
            width: 100%;
            border-radius: 208px 208px 0px 0px;
        }
    }

    .content {
        margin-top: 25px;

        h2 {
            a {
                color: #000;
                font-size: 25px;
                font-weight: 700;
                line-height: 40px;
                text-transform: capitalize;

                @media (max-width:991px) {
                    font-size: 20px;
                    line-height: 35px;
                }
            }
        }

        ul {
            list-style: none;

            li {
                color: #414141;
                font-size: 18px;
                font-weight: 400;
                line-height: 35px;
                display: inline-block;
                padding-right: 30px;

                @media (max-width:991px) {
                    padding-right: 20px;
                }

                @media (max-width:757px) {
                    padding-right: 8px;
                }

                img {
                    margin-right: 5px;
                    display: inline-block;
                }
            }
        }
    }
}


/*5.7 testimonial-section */
.testimonial-section {
    background: url(../../images/slider/bg.png);
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #F6F6F6;
        z-index: -11;
        opacity: .7;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 0px;
    }

    .slick-dots {
        position: unset;
        margin-top: 20px;
    }

    .slick-list.draggable {
        margin: 0 -20px;
    }

    button {
        display: block;
        width: 10px;
        height: 10px;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: rgba(43, 17, 15, 0.10);

        &::before {
            font-size: 0;
        }
    }

    .slick-dots li button {
        width: 15px;
        height: 15px;
        background: rgba(43, 17, 15, 0.10);
        margin: 0 3px;
    }

    .slick-dots li button:before {
        font-size: 0px;
    }

    li.slick-active button {
        background-color: $theme-primary-color;
    }

    .slick-slider {
        margin: -20px;

        .slick-slide {
            padding: 20px;
        }
    }

}

//testimonial-card
.testimonial-card {
    padding: 60px 50px;
    background: $white;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    max-width: 643px;

    @media (max-width:1199px) {
        padding: 40px 25px;
    }

    @media (max-width:991px) {
        max-width: 100%;
    }

    .top-content {
        display: flex;
        align-items: center;

        @media (max-width:575px) {
            display: block;
        }

        .image {
            width: 120px;
            height: 120px;

            @media (max-width:575px) {
                margin-bottom: 10px;
            }

            @media (max-width:1199px) {
                width: 100px;
                height: 100px;
            }

            img {
                width: 100%;
                border-radius: 100px;
            }
        }

        .title {
            padding-left: 20px;

            @media (max-width:575px) {
                padding-left: 0;
            }

            h2 {
                color: #262626;
                font-size: 25px;
                font-weight: 700;
                line-height: 40px;
                text-transform: capitalize;
                margin-bottom: 0;
            }

            span {
                color: #525252;
                font-size: 18px;
                font-weight: 400;
                line-height: 30px;
            }
        }

    }

    .text {
        color: #525252;
        font-size: 22px;
        font-weight: 400;
        line-height: 40px;
        margin-bottom: 0;
        margin-top: 30px;

        @media (max-width:575px) {
            margin-top: 15px;
            font-size: 18px;
            line-height: 30px;
        }
    }

    .icon {
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: -1;

        @media (max-width:1199px) {
            top: 20px;
            right: 20px;
        }
    }
}